import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';
import App from './containers/App';
import config from './config';
import { Provider } from 'react-redux';
import store from './redux/store/index';
import './styles/base.scss';
import './styles/icon.scss';
const tagManagerArgs = { gtmId: config.gtm_id };
TagManager.initialize(tagManagerArgs);
ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<React.StrictMode>
				<App />
			</React.StrictMode>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
