import * as React from 'react';
import { Button, Icon, ButtonProps } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import copy from 'clipboard-copy';

interface CopyButtonProps extends ButtonProps {
	content: string;
	hideIcon?: boolean;
	label?: string;
	toast?: string;
}
const CopyButton: React.FC<CopyButtonProps> = React.memo(
	({
		content = '',
		hideIcon,
		label,
		labelPosition = 'left',
		size = 'tiny',
		...props
	}) => {
		return document.queryCommandSupported('copy') ? (
			<div>
				<Button
					primary
					icon={!hideIcon}
					labelPosition={labelPosition}
					size={size}
					content={
						<>
							{!hideIcon && <Icon name='copy' />}
							{label || 'Copy'}
						</>
					}
					onClick={() => {
						copy(content).then(() =>
							toast.info(props.toast || 'Copied to clipboard!')
						);
					}}
				/>
			</div>
		) : null;
	}
);

export default CopyButton;
