import React from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import './myprojects.scss';
import { ProjectActions } from '../../redux/actions/project';

const MyProjects: React.FC<any> = (props) => {
	// const [open, setOpen] = React.useState(false);
	const history = useHistory();
	const { projects, setCurrentProject } = props;
	return (
		<div className='page-container'>
			<div className='page-container-inner card radius-sm'>
				<div className='page-header'>
					<div>
						<h1>My Projects</h1>
						<p>You can mange your projects form here.</p>
					</div>
					<Link to='/new'>
						<Button primary>
							<i className='icon-add right'></i>New Project
						</Button>
					</Link>
				</div>
				<div className='page-content'>
					<div className='grid-20'>
						{projects.length
							? projects.map((project) => (
									<div
										key={project.project_id}
										className='project-card card shadow radius-sm p-0'
									>
										{/* <div className='project-thumbnail'>
											<div className='icon'>
												<img src={ProjectThumbnail} alt='project thumbnail' />
											</div>
										</div> */}
										<div className='project-content'>
											<div className='title'>{project.name}</div>
											<div className='url'>{project.url}</div>
											<div className='tag'>Free</div>
										</div>
										<div className='project-action'>
											<Link
												onClick={() => {
													setCurrentProject(project);
													history.push('/overview');
												}}
											>
												<span>Get Started</span>
												<i className='icon-arrow--right'></i>
											</Link>
										</div>
									</div>
							  ))
							: null}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	projects: state.projects,
});

const mapDispatchToProps = (dispatch) => {
	return {
		setCurrentProject: (project: any) =>
			dispatch({
				type: ProjectActions.SET_CURRENT_PROJECT,
				payload: project,
			}),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProjects);
