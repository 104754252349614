import { IAction } from './index';
import { UserActions } from '../actions/user';

export function setUser(state = {}, action: IAction) {
	switch (action.type) {
		case UserActions.SET_PROFILE:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
}
