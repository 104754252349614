import React, { useState } from 'react';
import axios from 'axios';
import config from '../../config';
import { Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProjectActions } from '../../redux/actions/project';
import './myprojects.scss';
import { toast } from 'react-toastify';
const addProjectUrl = `${config.api_endpoint}/projects/new`;

const AddProject: React.FC<any> = (props) => {
	const [project, setProject] = useState({ name: '', url: '' });
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const history = useHistory();

	const { user, projects, setProjects, setCurrentProject } = props;
	return (
		<div className='page-container add-project'>
			{/* {user.first_login ? (
				<h1>Create your first project to get started!</h1>
			) : null} */}
			<div className='page-container-inner card radius-sm '>
				<div className='page-content'>
					{user.first_login ? (
						<React.Fragment>
							<div className='title '>Create project</div>
							<div className='subtitle'>
								Why don't you start with first project?
							</div>
						</React.Fragment>
					) : (
						<React.Fragment>
							<div className='title mb-sm'>Create project</div>
						</React.Fragment>
					)}
					<Form>
						<Form.Field>
							<label>Website URL</label>
							<input
								name='url'
								value={project.url}
								required
								type='url'
								placeholder='Enter your website domain'
								onChange={(e) =>
									setProject({ ...project, url: e.target.value })
								}
							/>
						</Form.Field>

						<Form.Field>
							<label>Project Name</label>
							<input
								name='name'
								value={project.name}
								required
								type='text'
								placeholder='Enter you project name'
								onChange={(e) =>
									setProject({ ...project, name: e.target.value })
								}
							/>
							<span className='error'>{error}</span>
						</Form.Field>

						{!user.first_login ? (
							<div className='btn-wrap'>
								<Button
									basic
									onClick={() => {
										const path = projects.length ? '/overview' : '/projects';
										history.push(path);
									}}
								>
									Cancel
								</Button>

								<Button
									primary
									loading={loading}
									onClick={(e) => {
										setLoading(true);
										e.preventDefault();
										axios
											.post(
												addProjectUrl,
												{
													...project,
													customerID: user.customer_id,
												},
												{
													headers: {
														Authorization: `Bearer ${localStorage.getItem(
															'AccessToken'
														)}`,
													},
												}
											)
											.then(({ data }) => {
												if (!data.data) {
													if (data.error === 'Project name already exits') {
														toast.error('project name already exists');
														setLoading(false);
														return;
													}
													setError('project name is a required field.');
													setLoading(false);
													return;
												}
												const { data: newProject } = data;

												setProjects([...projects, newProject]);
												setCurrentProject(newProject);
												setLoading(false);
												setError('');
												history.push('/overview');
											});
									}}
								>
									Create Project
								</Button>
							</div>
						) : (
							<div>
								<Button
									className='full-width'
									primary
									loading={loading}
									onClick={(e) => {
										setLoading(true);
										e.preventDefault();
										axios
											.post(
												addProjectUrl,
												{
													...project,
													customerID: user.customer_id,
												},
												{
													headers: {
														Authorization: `Bearer ${localStorage.getItem(
															'AccessToken'
														)}`,
													},
												}
											)
											.then(({ data }) => {
												if (!data.data) {
													if (data.error === 'Project name already exits') {
														toast.error('project name already exists');
														setLoading(false);
														return;
													}
													setError('project name is a required field.');
													setLoading(false);
													return;
												}
												const { data: newProject } = data;
												setProjects([...projects, newProject]);
												setCurrentProject(newProject);
												setLoading(false);
												setError('');
												history.push('/overview');
											});
									}}
								>
									Create Project
								</Button>
							</div>
						)}
					</Form>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.user,
	projects: state.projects,
});

const mapDispatchToProps = (dispatch) => ({
	setCurrentProject: (project: any) =>
		dispatch({
			type: ProjectActions.SET_CURRENT_PROJECT,
			payload: project,
		}),
	setProjects: (projects: any) =>
		dispatch({
			type: ProjectActions.SET_PROJECTS,
			payload: projects,
		}),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddProject);

/**
  
 <div>
						<Modal
							size='tiny'
							closeIcon
							open={open}
							trigger={
								<Button primary>
									<i className='icon-add right'></i>New Project
								</Button>
							}
							onClose={() => setOpen(false)}
							onOpen={() => setOpen(true)}
						>
							<div className='ui header'>
								<div className='content'>Create a Project</div>
							</div>
							<Modal.Content>
								<Form>
									<Form.Field>
										<label>Website URL</label>
										<input
											type='text'
											placeholder='Enter your website domain'
										/>
									</Form.Field>
									<Form.Field>
										<label>Project Name</label>
										<input type='text' placeholder='Eneter you project name' />
									</Form.Field>
								</Form>
							</Modal.Content>
							<Modal.Actions>
								<Button basic onClick={() => setOpen(false)}>
									Cancel
								</Button>
								<Button primary onClick={() => setOpen(false)}>
									Let’s go!
								</Button>
							</Modal.Actions>
						</Modal>
					</div>

					<div className='project-card card shadow radius-sm p-0 add-new-card'>
							<Modal
								size='tiny'
								closeIcon
								open={open}
								trigger={
									<div className='btn-ghost'>
										<i className='icon-add right'></i>New Project
									</div>
								}
								onClose={() => setOpen(false)}
								onOpen={() => setOpen(true)}
							>
								<div className='ui header'>
									<div className='content'>Create a Project</div>
								</div>
								<Modal.Content>
									<Form>
										<Form.Field>
											<label>Website URL</label>
											<input
												type='text'
												placeholder='Enter your website domain'
											/>
										</Form.Field>
										<Form.Field>
											<label>Project Name</label>
											<input
												type='text'
												placeholder='Eneter you project name'
											/>
										</Form.Field>
									</Form>
								</Modal.Content>
								<Modal.Actions>
									<Button basic onClick={() => setOpen(false)}>
										Cancel
									</Button>
									<Button primary onClick={() => setOpen(false)}>
										Let’s go!
									</Button>
								</Modal.Actions>
							</Modal>
						</div>
 */
