function getConfig() {
	return {
		gtm_id: process.env.REACT_APP_GTM_ID || 'GTM-5RD5FZ7',
		mojoauth_apikey:
			process.env.REACT_APP_MOJOAUTH_APIKEY ||
			'62f8ed2a-41b1-4a46-b4ea-0d6456c239dd',
		api_endpoint:
			process.env.REACT_APP_API_ENDPOINT ||
			'https://dev-console-api.mojoauth.com',
	};
}

export default getConfig();
