import React from 'react';
import { Dropdown, Button } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProjectActions } from '../../redux/actions/project';
import logo from '../../assets/images/logo.svg';
import './header.scss';
export interface HeaderProps {
	hidePaths: string[];
	user: any;
	projects: any;
	currentProject: any;
	setCurrentProject: any;
}

const getNameFromEmail = (email: string) => {
	return email.substr(0, email.indexOf('@'));
};
const Header: React.FC<HeaderProps> = (props) => {
	const history = useHistory();
	const { user, currentProject, projects, setCurrentProject } = props;

	return (
		<header>
			<div className='logo'>
				<Link to='/projects'>
					<img src={logo} width='200' title='MojoAuth' alt='MojoAuth' />
				</Link>
			</div>
			<div className='account-info'>
				<div className='project'>
					<Dropdown
						text={currentProject ? currentProject.name : 'Add New Project'}
					>
						<Dropdown.Menu>
							<Link to='/projects'>
								<i className='icon-chevron--left right'></i> My Projects
							</Link>
							<Dropdown.Divider />
							{projects.length
								? projects.map((project) => (
										<Dropdown.Item
											text={project.name}
											description='Free'
											key={project.project_id}
											onClick={() => setCurrentProject(project)}
											className={
												currentProject.project_id === project.project_id
													? 'active'
													: ''
											}
										/>
								  ))
								: null}

							<Dropdown.Divider />
							<Link to='/new'>
								<Button primary>
									<i className='icon-add right'></i>New Project
								</Button>
							</Link>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className='account'>
					{/* <Icon name='user' /> */}

					<Dropdown text={user.email ? getNameFromEmail(user.email) : ''}>
						<Dropdown.Menu>
							{/* <Popup
								trigger={<Dropdown.Item text={user.email} />}
								content={user.email}
								inverted
							/> */}
							{/* <Dropdown.Item text='Account' />
								<Dropdown.Divider /> */}
							<Dropdown.Item
								text='Logout'
								onClick={() => {
									localStorage.removeItem('AccessToken');
									history.push('/');
								}}
							/>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</div>
		</header>
	);
};

const mapStateToProps = (state: any) => ({
	user: state.user,
	projects: state.projects,
	currentProject: state.currentProject,
});

const mapDispatchToProps = (dispatch) => ({
	setCurrentProject: (project: any) =>
		dispatch({
			type: ProjectActions.SET_CURRENT_PROJECT,
			payload: project,
		}),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
