import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { isLoggedIn } from '../../utils/index';

export const ProtectedRoute: React.FC<RouteProps> = (props) => {
	const { pathname, search } = useLocation();

	if (isLoggedIn()) {
		const { children, ...otherProps } = props;
		return <Route {...otherProps}>{children}</Route>;
	} else return <Redirect to={`/signin?redirect=${pathname + search}`} />;
};
