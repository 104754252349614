import { IAction } from './index';
import { ProjectActions } from '../actions/project';

const defaultCurrentProject = {};

export function setCurrentProject(
	state = defaultCurrentProject,
	action: IAction
) {
	switch (action.type) {
		case ProjectActions.SET_CURRENT_PROJECT:
			return action.payload;
		default:
			return state;
	}
}

export function setProjects(state = [], action: IAction) {
	switch (action.type) {
		case ProjectActions.SET_PROJECTS:
			return action.payload;
		case ProjectActions.ADD_PROJECT:
			return [...state, action.payload];
		default:
			return state;
	}
}
