import React from 'react';
import './auth.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { UserActions } from '../../redux/actions/user';
import { ProjectActions } from '../../redux/actions/project';
import config from '../../config';
import axios from 'axios';
import { toast } from 'react-toastify';
declare global {
	interface Window {
		MojoAuth: any;
	}
}

interface AuthProps {
	setUser: (user: any) => void;
	setCurrentProject: (id: string) => void;
	setProjects: (projects: any[]) => void;
}

const createCustomerUrl = `${config.api_endpoint}/customer/create`;
const getAllProjectsUrl = `${config.api_endpoint}/projects`;

const Auth: React.FC<AuthProps> = (props) => {
	const { setUser, setCurrentProject, setProjects } = props;
	const history = useHistory();
	const { search } = useLocation();

	React.useEffect(() => {
		const callback = ({ data }) => {
			//FIXME set access token, later to be replaces by cookie

			// make api call to console backend
			axios
				.post(
					createCustomerUrl,
					{
						email: data.email,
					},
					{
						headers: {
							Authorization: `Bearer ${data.access_token}`,
						},
					}
				)
				.then(({ data }) => {
					// check for any errors
					if (!data.data) {
						toast.error(data.message);
						return;
					}
					const { data: user } = data;
					// set the customer data in redux store
					localStorage.setItem('AccessToken', user.access_token);

					setUser(user);
					axios
						.get(getAllProjectsUrl, {
							headers: {
								Authorization: `Bearer ${user.access_token}`,
							},
							params: {
								customerID: user.customer_id,
							},
						})
						.then(({ data }) => {
							// handle error here
							if (!data.data) {
								toast.error(data.message);
							}
							let { data: projects } = data;
							// FIXME: data is null not [] in api
							if (projects === null) {
								projects = [];
							}
							setProjects(projects);

							if (user.first_login) {
								history.push('/new');
							} else {
								if (projects.length) {
									const redirect = search.startsWith('?redirect=')
										? search.substr(10)
										: '/overview';
									setCurrentProject(projects[0]);
									history.push(redirect);
								} else {
									history.push('/new');
								}
							}
						});
				});
		};
		const mojoauthConfig = {
			apikey: config.mojoauth_apikey,
			callback,
		};
		const mojoauth = new window.MojoAuth(mojoauthConfig);
		mojoauth.signInWithMagicLink();
		// mojoauth.signInWithEmailOTP();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='signin'>
			<div id='mojoauth-passwordless-form'></div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setUser: (user: any) =>
			dispatch({
				type: UserActions.SET_PROFILE,
				payload: user,
			}),
		setCurrentProject: (id: string) =>
			dispatch({
				type: ProjectActions.SET_CURRENT_PROJECT,
				payload: id,
			}),
		setProjects: (projects: any[]) =>
			dispatch({
				type: ProjectActions.SET_PROJECTS,
				payload: projects,
			}),
	};
};
export default connect(null, mapDispatchToProps)(Auth);
