import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Message } from 'semantic-ui-react';
import CopyButton from '../../components/CopyButton';
import axios from 'axios';
import './overview.scss';
import config from '../../config';
import { toast } from 'react-toastify';
const url = `${config.api_endpoint}/secret/show`;
const Overview: React.FC<any> = React.memo(({ currentProject }) => {
	const [showSecret, setShowSecret] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [secret, setSecret] = useState<string>('');
	return (
		<div className='page-container'>
			<div className='page-container-inner card radius-sm '>
				<div className='page-header'>
					<div>
						<h1>Overview</h1>
						<p>You can check high level overview for your project.</p>
					</div>
					{/* <div>
						<Button primary>
							Try your Project <i className='icon-new-tab left'></i>
						</Button>
					</div> */}
				</div>
				<div className='page-content'>
					{/* <Statistics /> */}
					<div className='api-credentials card radius-sm shadow p-32 '>
						<h3>API Credentials</h3>
						<div className='ui form'>
							<div className='field'>
								<label>Api Key</label>
							</div>
						</div>

						<div className='apikey custom-input justify-between'>
							<div>{currentProject.apikey}</div>
							<CopyButton size='mini' content={currentProject.apikey} />
						</div>
						{showSecret ? (
							<React.Fragment>
								<div className='ui form'>
									<div className='field'>
										<label>Api Secret</label>
									</div>
								</div>
								<div className='apikey custom-input justify-between'>
									<div>{secret}</div>
									<CopyButton size='mini' content={secret} />
								</div>
							</React.Fragment>
						) : (
							<Form style={{ marginBottom: '16px' }}>
								<Form.Field>
									<label>Api Secret</label>
									<span className='help-text'>
										Keep Secret always secret, do not share with anyone or use
										on client side, also we do not store plain text secret to
										copy it regenerate.
									</span>
									<input
										type='password'
										placeholder='Regenerate Secret'
										value='*********'
										readOnly
									/>
								</Form.Field>
							</Form>
						)}
						{showSecret && (
							<Message warning size='mini' compact>
								Make sure to copy your new Secret now. You won’t be able to see
								it again!
							</Message>
						)}
						{/* {showSecret ? (
							<Button
								type='button'
								basic
								color='purple'
								onClick={(e) => setShowSecret(false)}
							>
								Hide Secret
							</Button>
						) : ( */}
						<Button
							type='button'
							basic
							color='purple'
							loading={loading}
							onClick={(e) => {
								setLoading(true);
								axios
									.get(url, {
										params: {
											project_id: currentProject.project_id,
										},
										headers: {
											Authorization: `Bearer ${localStorage.getItem(
												'AccessToken'
											)}`,
										},
									})
									.then(({ data }) => {
										if (!data.data) {
											toast.error('Some error occured, please try again!');
											setLoading(false);
										} else {
											const { data: response } = data;
											setSecret(response.apisecret);
											setShowSecret(true);
											setLoading(false);
										}
									});
							}}
						>
							Regenerate Secret
						</Button>

						{/* )} */}
					</div>
				</div>
			</div>
		</div>
	);
});

// const Statistics = (props) => {
// 	return (
// 		<div className='statics grid-33'>
// 			<div className='total-user card radius-sm shadow p-32'>
// 				<h2>230</h2>
// 				<p>Total User</p>
// 			</div>
// 			<div className='total-login card radius-sm shadow p-32'>
// 				<h2>450</h2>
// 				<p>Total Login</p>
// 			</div>
// 			<div className='monthly-login card radius-sm shadow p-32'>
// 				<h2>120</h2>
// 				<p>Monthly Login</p>
// 			</div>
// 		</div>
// 	);
// };

const mapStateToProps = (state) => ({
	currentProject: state.currentProject,
});

export default connect(mapStateToProps)(Overview);
