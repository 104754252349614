/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Button, Form, Message, Tab } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { ProjectActions } from '../../redux/actions/project';
import './branding.scss';
// import companyLogo from '../../assets/images/company_logo.svg';
import checkEmailLogo from '../../assets/images/check_email.svg';
import verifyEmailLogo from '../../assets/images/verify_email.svg';
import axios from 'axios';
import { FALLBACK } from '../App';
import config from '../../config';
import pick from 'lodash/pick';
import { toast } from 'react-toastify';
const updateProjectUrl = `${config.api_endpoint}/projects`;

const options = [
	{ key: 'login', text: 'Login Widget', value: 'login' },
	{ key: 'Verification', text: 'Verification Page', value: 'verification' },
	{ key: 'Verified', text: 'Verified Page', value: 'verified' },
	{
		key: 'emailtemplate',
		text: 'Verification Email',
		value: 'email',
	},
];

const fontOptions = [
	{ name: 'Arial', url: '', text: 'Arial', idSuffix: 'Arial' },
	{ name: 'Helvetica', url: '', text: 'Helvetica', idSuffix: 'Helvetica' },
	{ name: 'Times', url: '', text: 'Times', idSuffix: 'Times' },
	{ name: 'CourierNew', url: '', text: 'Courier New', idSuffix: 'CourierNew' },
	{ name: 'Courier', url: '', text: 'Courier', idSuffix: 'Courier' },
	{ name: 'Verdana', url: '', text: 'Verdana', idSuffix: 'Verdana' },
	{ name: 'Palatino', url: '', text: 'Palatino', idSuffix: 'Palatino' },
	{ name: 'Garamond', url: '', text: 'Garamond', idSuffix: 'Garamond' },
	{ name: 'Bookman', url: '', text: 'Bookman', idSuffix: 'Bookman' },
	{
		name: 'ComicSansMS',
		url: '',
		text: 'Comic Sans MS',
		idSuffix: 'ComicSansMS',
	},
	{
		name: 'TrebuchetMS',
		url: '',
		text: 'Trebuchet MS',
		idSuffix: 'TrebuchetMS',
	},
	{ name: 'ArialBlack', url: '', text: 'Arial Black', idSuffix: 'ArialBlack' },
	{ name: 'Impact', url: '', text: 'Impact', idSuffix: 'Impact' },
	{
		name: 'TimesNewRoman',
		url: '',
		text: 'Times New Roman',
		idSuffix: 'TimesNewRoman',
	},
];
interface BrandingProps {
	setCurrentProject: (project) => void;
	setProjects: (projects) => void;
	projects: any;
	currentProject: any;
}

const CustomizationMessage = ({ previewType }) => (
	<div style={{ minHeight: '350px', padding: '0 1rem 1rem' }}>
		<Message info size='small'>
			{`No customizations available for ${previewType} template`}
		</Message>
	</div>
);

const Branding: React.FC<BrandingProps> = (props) => {
	const { setCurrentProject, setProjects, projects, currentProject } = props;
	const [config, setConfig] = useState(currentProject.config);

	const [previewType, setPreviewType] = useState('login');
	const [loading, setLoading] = useState<boolean>(false);
	React.useEffect(() => {
		setConfig(currentProject.config);
	}, [currentProject.config]);

	const onSubmitHandler = (e) => {
		setLoading(true);
		e.preventDefault();
		const updatedProject = { ...currentProject, config };

		axios
			.put(
				`${updateProjectUrl}/${currentProject.project_id}`,
				pick(updatedProject, ['name', 'url', 'config']),
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AccessToken')}`,
					},
				}
			)
			.then(({ data }) => {
				if (!data.data) {
					toast.error(data.message);
					setLoading(false);
					return;
				}
				const { data: projectReponse } = data;
				const filteredProjects = projects.filter(
					(project) => project.project_id !== projectReponse.project_id
				);
				setProjects([...filteredProjects, projectReponse]);
				setCurrentProject(projectReponse);
				setLoading(false);
				toast.info('Updated successfully');
			});
	};
	const panes = [
		{
			menuItem: 'Branding',
			render: () => {
				if (['verified', 'verification'].includes(previewType)) {
					return <CustomizationMessage previewType={previewType} />;
				}
				return (
					<Tab.Pane attached={false}>
						<Form>
							{previewType === 'email' && (
								<Form.Field>
									<label>Company Name</label>
									<input
										type='text'
										name='brand'
										value={config.brand}
										onChange={(e) => {
											setConfig({ ...config, [e.target.name]: e.target.value });
										}}
									/>
								</Form.Field>
							)}
							{/* <Form.Field>
							<label>Background Color</label>
							<div className='custom-input'>
								<input
									type='color'
									name='background'
									value={config.background}
									onChange={(e) => {
										setConfig({ ...config, [e.target.name]: e.target.value });
									}}
								/>
								<span>{config.background}</span>
							</div>
						</Form.Field> */}

							<Form.Field>
								<label>Font Family</label>
								<select
									name='font'
									onChange={(e) => {
										const { font } = config;
										font.family = e.target.value;
										const usedFont = fontOptions.find(
											(font) => font.name === e.target.value
										);
										font.url = usedFont[e.target.value];
										setConfig({ ...config, font });
									}}
									value={config.font.family}
								>
									<option>Select Font</option>
									{fontOptions.map((font) => (
										<option key={font.name} value={font.name}>
											{font.name}
										</option>
									))}
								</select>
							</Form.Field>
							<Form.Field>
								<label>Company Logo Url</label>
								<input
									type='url'
									name='brand_logo'
									value={config.brand_logo}
									onChange={(e) => {
										setConfig({ ...config, [e.target.name]: e.target.value });
									}}
								/>
							</Form.Field>
							{/* <Form.Field>
							<label>Favicon Url</label>
							<input
								type='url'
								name='favicon'
								value={config.favicon}
								onChange={(e) => {
									setConfig({ ...config, [e.target.name]: e.target.value });
								}}
							/>
						</Form.Field> */}
							<Form.Field>
								<label>Button Color</label>
								<div className='custom-input'>
									<input
										type='color'
										name='btn_color'
										value={config.btn_color}
										onChange={(e) => {
											setConfig({ ...config, [e.target.name]: e.target.value });
										}}
									/>
									<span>{config.btn_color}</span>
								</div>
							</Form.Field>
							<div className='btn-wrap'>
								{/* <Button type='submit' basic>
								Cancel
							</Button> */}
								{/* <Button type='submit' primary onClick={onSubmitHandler}>
								Save
							</Button> */}
							</div>
						</Form>
					</Tab.Pane>
				);
			},
		},
		{
			menuItem: 'Customization',
			render: () => {
				if (['verified', 'verification', 'email'].includes(previewType)) {
					return <CustomizationMessage previewType={previewType} />;
				}

				return (
					<Tab.Pane attached={false}>
						<Form>
							<Form.Field>
								<label>Heading</label>
								<input
									type='text'
									name='heading'
									value={config.login.heading}
									onChange={(e) => {
										const { login } = config;
										login.heading = e.target.value;
										setConfig({ ...config, login });
									}}
								/>
							</Form.Field>
							<Form.Field>
								<label>Sub Heading</label>
								<input
									type='text'
									name='sub_heading'
									value={config.login.sub_heading}
									onChange={(e) => {
										const { login } = config;
										login.sub_heading = e.target.value;
										setConfig({ ...config, login });
									}}
								/>
							</Form.Field>
							<Form.Field>
								<label>Button Text</label>
								<input
									type='text'
									name='button_text'
									value={config.login.button.text}
									onChange={(e) => {
										const { login } = config;
										const { button } = login;
										button.text = e.target.value;
										login.button = button;
										setConfig({ ...config, login });
									}}
								/>
							</Form.Field>
							<Form.Field>
								<label>Textbox Placeholder</label>
								<input
									type='text'
									name='placeholder_text'
									value={config.login.input.placeholder}
									onChange={(e) => {
										const { login } = config;
										const { input } = login;
										input.placeholder = e.target.value;
										login.input = input;
										setConfig({ ...config, login });
									}}
								/>
							</Form.Field>

							<div className='btn-wrap'>
								{/* <Button type='submit' basic>
								Cancel
							</Button> */}
								{/* <Button type='submit' primary onClick={onSubmitHandler}>
								Save
							</Button> */}
							</div>
						</Form>
					</Tab.Pane>
				);
			},
		},
	];

	if (!Object.keys(currentProject).length && currentProject.config) {
		return FALLBACK;
	}

	return (
		<div className='page-container'>
			<div className='page-container-inner card radius-sm branding'>
				<div className='page-header'>
					<div>
						<h1>Branding</h1>
						<p>
							Customize your text, logo, and color for your authentication pages
							as per your branding standards.
						</p>
					</div>
					{/* <div>
							<Button primary>
								<span>How to Customize</span>
								<i className='icon-new-tab left'></i>
							</Button>
							<Button primary>
								Live Preview <i className='icon-new-tab left'></i>
							</Button>
						</div> */}
				</div>
				<div className='page-content'>
					<div className='card shadow radius-sm p-0'>
						<div className='grid-60-40'>
							<div className='preview'>
								<div className='header'>
									<div>Preview</div>
									<Form>
										<Form.Group>
											<select
												name='preview type'
												value={previewType}
												onChange={(e) => setPreviewType(e.target.value)}
											>
												{options.map((option) => (
													<option key={option.key} value={option.value}>
														{option.text}
													</option>
												))}
											</select>
										</Form.Group>
									</Form>
								</div>

								<div
									className='main authentication'
									id='branding-preview'
									style={{
										fontFamily: config.font.family,
									}}
								>
									<Preview type={previewType} config={config} />
								</div>
							</div>
							<div className='branding-form'>
								<div className='tab-wrap'>
									<Tab
										menu={{ secondary: true, pointing: true }}
										panes={panes}
									/>

									<div className='btn-wrap'>
										{['verified', 'verification'].includes(
											previewType
										) ? null : (
											<Button
												type='submit'
												loading={loading}
												primary
												onClick={onSubmitHandler}
											>
												Update
											</Button>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const Preview: React.FC<any> = (props) => {
	const { type, config } = props;

	if (type === 'login') {
		return (
			<div className='card shadow v-center'>
				<div className='logo'>
					<img src={config.brand_logo} alt='company logo' />
				</div>
				<div className='title'>{config.login.heading}</div>
				<div className='subtitle'>{config.login.sub_heading}</div>
				<Form>
					<Form.Field>
						<input type='text' placeholder={config.login.input.placeholder} />
					</Form.Field>
					<Form.Field>
						<div className='btn-wrap'>
							<Button
								type='submit'
								style={{ backgroundColor: config.btn_color }}
							>
								{config.login.button.text}
							</Button>
						</div>
					</Form.Field>
					<p>by clicking login I agree with terms of use and privacy policy.</p>
				</Form>
			</div>
		);
	} else if (type === 'verified') {
		return (
			<div className='card shadow text-center v-center'>
				<div className='logo'>
					<img src={checkEmailLogo} alt='email logo' />
				</div>
				<div className='title'>Successfully verified</div>
				<div className='subtitle'>
					Please navigate back to the login
					<br />
					page.
				</div>
			</div>
		);
	} else if (type === 'verification') {
		return (
			<div className='card shadow text-center v-center'>
				<div className='logo'>
					<img src={verifyEmailLogo} alt='verify email' />
				</div>
				<div className='title'>Check your mail</div>
				<div className='subtitle'>
					A verification link has been sent to your email address.
				</div>
			</div>
		);
	} else if (type === 'email') {
		return (
			// <div className='card shadow v-center'>
			// 	<div>
			// 		<img src={config.brand_logo} width='228' alt='company logo' />
			// 	</div>
			// 	<div>
			// 		<h1>Login to {config.brand}</h1>
			// 		<p>Click the button below to confirm it's you</p>
			// 	</div>
			// 	<a href='#'>Verify your Email</a>
			// 	<div>
			// 		If you did NOT request to verify this email address, do not click on
			// 		the link.
			// 		<br />
			// 		<br />
			// 		<span>
			// 			Yours truly,
			// 			<br />
			// 			{config.brand}
			// 		</span>
			// 	</div>
			// 	<div>&nbsp;</div>
			// 	<div>
			// 		If you’re having trouble with the button 'Yes, that's me', copy and
			// 		paste the URL below into your web browser.
			// 		<br />
			// 		<br />
			// 		<a href='#'>Magic Link</a>
			// 	</div>
			// </div>
			<table style={{ width: '600px', margin: '0 auto', background: '#fff' }}>
				<tr>
					<td>
						<div style={{ padding: '22px 0' }}>
							<img
								src={config.brand_logo}
								width='220'
								alt='brand logo'
								style={{ margin: '0 auto', display: 'block' }}
							/>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div style={{ padding: '0 22px 0' }}>
							<h1 style={{ fontSize: '24px', margin: '24px 0 0' }}>
								Login to {config.brand}
							</h1>
							<p style={{ fontSize: '16px', marginTop: '8px' }}>
								Click the button below to confirm it's you
							</p>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<a
							href='javascript:void();'
							style={{
								color: '#ffffff',
								backgroundColor: config.btn_color,
								display: 'block',
								fontSize: '20px',
								fontWeight: 'bold',
								lineHeight: '52px',
								maxWidth: '320px',
								textDecoration: 'none',
								width: '320px !important',
								height: '100%',
								margin: '32px auto',
								padding: '0',
								borderRadius: '4px',
								textAlign: 'center',
							}}
						>
							Verify your Email
						</a>
					</td>
				</tr>
				<tr>
					<td>
						<div style={{ padding: '16px 22px 40px' }}>
							If you did NOT request to verify this email address, do not click
							on the link.
							<br />
							<br />
							<span>
								Yours truly,
								<br />
								{config.brand}
							</span>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div style={{ borderTop: '1px solid #e7e7e7' }}>&nbsp;</div>
					</td>
				</tr>
				<tr>
					<td>
						<div style={{ padding: '16px 22px 22px' }}>
							If you’re having trouble with the button 'Yes, that's me', copy
							and paste the URL below into your web browser.
							<br />
							<br />
							<a
								href='javascript:void();'
								target='_blank'
								rel='noreferrer'
								style={{ textDecoration: 'none', color: '#5e00cf' }}
							>
								https://js.mojoauth.app/magic?a=80fe701a-0bc0-4ba3-9def-52177601bbd3&c=390259&i=424395&nc=111104&ncid=424396
							</a>
						</div>
					</td>
				</tr>
			</table>
		);
	}
};

const mapStateToProps = (state) => ({
	currentProject: state.currentProject,
	projects: state.projects,
});

const mapSDispatchToProps = (dispatch) => ({
	setProjects: (projects) =>
		dispatch({
			type: ProjectActions.SET_PROJECTS,
			payload: projects,
		}),
	setCurrentProject: (project) =>
		dispatch({
			type: ProjectActions.SET_CURRENT_PROJECT,
			payload: project,
		}),
});
export default connect(mapStateToProps, mapSDispatchToProps)(Branding);
