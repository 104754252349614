import { combineReducers } from 'redux';
import { setUser } from './user';
import { setCurrentProject, setProjects } from './project';

export interface IAction {
	type: string;
	payload?: any;
}

export const rootReducer = combineReducers({
	user: setUser,
	projects: setProjects,
	currentProject: setCurrentProject,
});
