import React, { useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import './settings.scss';
import config from '../../config';
import axios from 'axios';
import { ProjectActions } from '../../redux/actions/project';
import pick from 'lodash/pick';
import { toast } from 'react-toastify';
const updateProjectUrl = `${config.api_endpoint}/projects`;

const Settings: React.FC<any> = (props) => {
	// const [open, setOpen] = useState(false);
	const { currentProject, setCurrentProject, projects, setProjects } = props;
	const [project, updateProject] = useState({
		name: currentProject.name,
		url: currentProject.url,
	});
	const [loading, setLoading] = useState<boolean>(false);
	React.useEffect(() => {
		updateProject({ name: currentProject.name, url: currentProject.url });
	}, [currentProject]);
	return (
		<div className='page-container'>
			<div className='page-container-inner card radius-sm'>
				<div className='page-header'>
					<div>
						<h1>Settings</h1>
						<p>You can manage your project here.</p>
					</div>
				</div>
				<div className='page-content'>
					<div className='settings card p-0'>
						{/* <h3>Update Project	</h3> */}
						<Form>
							<Form.Field>
								<label>Website URL</label>
								<input
									type='text'
									value={project.url}
									onChange={(e) =>
										updateProject({ ...project, url: e.target.value })
									}
									name='url'
								/>
							</Form.Field>
							{/* <Form.Field>
								<label>Project Name</label>
								<input
									type='text'
									value={project.name}
									onChange={(e) =>
										updateProject({ ...project, name: e.target.value })
									}
									name='name'
								/>
							</Form.Field> */}
							<div className='btn-wrap'>
								{/* <Button type='submit' basic>
										Cancel
									</Button> */}
								<Button
									disabled={project.url === currentProject.url}
									type='submit'
									primary
									loading={loading}
									onClick={(e) => {
										setLoading(true);
										e.preventDefault();
										const updatedProject = { ...currentProject, ...project };

										axios
											.put(
												`${updateProjectUrl}/${currentProject.project_id}`,
												pick(updatedProject, ['name', 'url', 'config']),
												{
													headers: {
														Authorization: `Bearer ${localStorage.getItem(
															'AccessToken'
														)}`,
													},
												}
											)
											.then(({ data }) => {
												if (!data.data) {
													toast.error(data.message);
													setLoading(false);
													return;
												}
												const { data: projectReponse } = data;
												const filteredProjects = projects.filter(
													(project) =>
														project.project_id !== projectReponse.project_id
												);
												setProjects([...filteredProjects, projectReponse]);
												setCurrentProject(projectReponse);
												setLoading(false);
												toast.info('Updated successfully');
											});
									}}
								>
									Update
								</Button>
							</div>
						</Form>
						<hr className='my-32' />
					</div>
					{/* <div className='close-account card p-0'>
							<h3>Close Project</h3>
							<p>
								This action is irreversible. If you close this project, all
								login forms, protected pages, and login buttons from this
								project may return errors and not work properly.
							</p>
							<div className='btn-wrap'>
								<Modal
									size='tiny'
									closeIcon
									open={open}
									trigger={<Button color='red'>Close Project</Button>}
									onClose={() => setOpen(false)}
									onOpen={() => setOpen(true)}
								>
									<div className='ui header'>
										<div className='content'>Close this Project?</div>
									</div>
									<Modal.Content>
										<p>
											Your API keys will no longer work. This cannot be undone.
										</p>
									</Modal.Content>
									<Modal.Actions>
										<Button basic onClick={() => setOpen(false)}>
											Cancel
										</Button>
										<Button primary onClick={() => setOpen(false)}>
											Confirm
										</Button>
									</Modal.Actions>
								</Modal>
							</div>
						</div> */}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	currentProject: state.currentProject,
	projects: state.projects,
});

const mapSDispatchToProps = (dispatch) => ({
	setProjects: (projects) =>
		dispatch({
			type: ProjectActions.SET_PROJECTS,
			payload: projects,
		}),
	setCurrentProject: (project) =>
		dispatch({
			type: ProjectActions.SET_CURRENT_PROJECT,
			payload: project,
		}),
});
export default connect(mapStateToProps, mapSDispatchToProps)(Settings);
