// import React, { Component } from 'react';
import React, { useState } from 'react';
// import { Tab } from 'semantic-ui-react';
// import SyntaxHighlighter from 'react-syntax-highlighter';
// import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
// import * as crypto from 'crypto';
import axios from 'axios';
import { toast } from 'react-toastify';
// import * from 'crypto-js';
// import bg from '../../../public/assets/images/bg-1.svg';
import ClipLoader from 'react-spinners/ClipLoader';
import './landing.scss';
// import { render } from '@testing-library/react';
import '../../styles/variable.scss';
var CryptoJS = require('crypto-js');
const url = ` https://d3iwpnlb1swteo.cloudfront.net/development`;
const Landing: React.FC = () => {
	// 	const snippet = `  <script
	//     src='https://mojoauth-cdn.netlify.com/js/mojoauth.min.js'>
	//   </script>

	//   <script>
	//     const mojoauth = new MojoAuth(config);
	//     mojoauth.signInWithMagicLink();
	//   </script>`;
	// Add separate class for landing page

	React.useEffect(() => {
		const html = document.getElementsByTagName('html')[0];
		html.classList.add('landing-page');
		return () => {
			html.classList.remove('landing-page');
		};
	});

	const [passwordInput, setPassword] = useState('');
	const [displayStr, setDisplayStr] = useState('');
	const [showDisplayStr, setShowDisplayStr] = useState('');
	const [loading, setLoading] = useState(false);

	const something = (event) => {
		if (event.keyCode === 13) {
			setPassword(event.target.value);

			if (passwordInput === '') {
				toast.error('No password entered. Please try again!');
			} else {
				setLoading(true);
				var sha1hash = CryptoJS.SHA1(passwordInput);
				var fiveHash = sha1hash.toString().substr(0, 5);
				var hashNo = fiveHash.toUpperCase();
				axios
					.get(url, {
						params: {
							hash: hashNo,
						},
					})
					.then(({ data }) => {
						let flag = false;
						for (var i = 0; i < data.length; i++) {
							if (data[i].hash === sha1hash.toString().toUpperCase()) {
								flag = true;
								break;
							}
						}
						if (flag === true) {
							setDisplayStr(data[i].count);
							setShowDisplayStr('block');
						} else {
							setDisplayStr('Not Pwned');
							setShowDisplayStr('block');
						}
						setLoading(false);
						document.getElementById('displayString').style.display = 'block';
					})

					.catch((err) => {
						//This block mentions what to do in case the password is not pwned
						if (err.response.status === 404) {
							toast.error('Something went wrong! Please try again');
							console.log('Internal Server Error');
							setDisplayStr('Something went wrong! Please try again');
							setShowDisplayStr('block');
						} else {
							//Show a try again later response
							console.log('Internal Server Error');
							setDisplayStr('Something went wrong! Please try again');
							setShowDisplayStr('block');
						}
					});
			}
		}
	};

	return (
		<React.Fragment>
			{/* <!--Header section start here--> */}
			<section className='header d-flex'>
				<div className='h-center align-items-stretch'>
					<div className='logo'>
						<Link to='/'>
							<img
								// src='/assets/images/logo.svg'
								height='100'
								width='100'
								src='/assets/images/DBSlogo-small.png'
								alt='Breached.app'
								title='Breached.app'
							/>
						</Link>
					</div>
					<nav className='nav d-flex'>
						<ul className='d-flex'></ul>
						<ul className='nav-right v-center'>
							<li>
								<a
									// href='https://6ohvv8yvg2b.typeform.com/to/Q2OUOiTh'
									className='btn btn-primary'
								>
									Check if your Password is secure
								</a>
							</li>
						</ul>
					</nav>
				</div>
			</section>
			{/* <!--Header section end here--> */}

			{/* <!--Hero section start here--> */}
			<section className='hero py-80'>
				<div className='section-header text-center'>
					<div className='description text-center'>
						<h1>Keep track of your Passwords' security</h1>
						<p>
							When not kept track of in a long time, your passwords and email
							addresses can be compromised to various websites. With Breached,
							you can check if your passwords are secure.
							{/* <span>DataProt on Nov 19, 2019</span> */}
						</p>
						<div className='formtext'>
							<input
								type='password'
								id='password-input'
								style={{
									width: 600,
									height: 50,
									borderColor: '#5e00cf',
									borderWidth: 3,
								}}
								onChange={(ev) => {
									document.getElementById('displayString').style.display =
										'none';
									setPassword(ev.target.value);
								}}
								onKeyDown={(e) => something(e)}
								placeholder='Enter your password.'
							/>
						</div>
						<ClipLoader loading={loading} size={100} />
						<div className='btn-group m-auto'>
							<div className='btn'>
								<Button
									className='btn btn-hover'
									type='submit'
									onClick={(e) => {
										// let inputData = passwordInput
										// var inputData = (document.getElementById("password-input") as HTMLInputElement).value;
										if (passwordInput === '') {
											toast.error('No password entered. Please try again!');
										} else {
											setLoading(true);
											var sha1hash = CryptoJS.SHA1(passwordInput);
											var fiveHash = sha1hash.toString().substr(0, 5);
											var hashNo = fiveHash.toUpperCase();
											axios
												.get(url, {
													params: {
														hash: hashNo,
													},
												})
												.then(({ data }) => {
													let flag = false;
													for (var i = 0; i < data.length; i++) {
														if (
															data[i].hash === sha1hash.toString().toUpperCase()
														) {
															flag = true;
															break;
														}
													}
													if (flag === true) {
														setDisplayStr(data[i].count);
														setShowDisplayStr('block');
													} else {
														setDisplayStr('Not Pwned');
														setShowDisplayStr('block');
													}
													setLoading(false);
													document.getElementById(
														'displayString'
													).style.display = 'block';
												})

												.catch((err) => {
													//This block mentions what to do in case the password is not pwned
													if (err.response.status === 404) {
														toast.error(
															'Something went wrong! Please try again'
														);
														console.log('Internal Server Error');
														setDisplayStr(
															'Something went wrong! Please try again'
														);
														setShowDisplayStr('block');
													} else {
														//Show a try again later response
														console.log('Internal Server Error');
														setDisplayStr(
															'Something went wrong! Please try again'
														);
														setShowDisplayStr('block');
													}
												});
										}
									}}
								>
									<span>Password pwned?</span>

									<svg
										width='20'
										height='20'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
										className='feather feather-arrow-right'
									>
										<line x1='5' y1='12' x2='19' y2='12'></line>
										<polyline points='12 5 19 12 12 19'></polyline>
									</svg>
								</Button>
							</div>
							<div className='btn'>
								{/* ---Try again button--- */}

								{/* <Button className = 'btn btn-hover'
							onClick={(e) => { 
								(document.getElementById("password-input") as HTMLInputElement).value = "";
								document.getElementById("displayString").style.display="none";
								setPassword("")
							}}
							>
									<span> Try again </span>
									<svg
										// style {{}}
										
										width='20'
										height='20'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
										className='feather feather-arrow-right'
										
									>
										<line x1='5' y1='12' x2='19' y2='12'></line>
										<polyline points='12 5 19 12 12 19'></polyline>
									</svg>
								</Button> */}
							</div>

							<div
								id='displayString'
								style={{
									display: showDisplayStr ? 'block' : 'none',
									margin: '16px',
									width: 800,
									background:
										displayStr === 'Not Pwned' ? '#97ff89' : '#FF4E4E',
									color: displayStr === 'Not Pwned' ? '#000000' : '#ffffff',
								}}
							>
								<p>
									{displayStr === 'Not Pwned'
										? 'Congratulations! Your password is safe.'
										: 'Oh no - Password compromised!'}
								</p>
								<p style={{ fontSize: 20 }}>
									{displayStr === 'Not Pwned'
										? 'Your password has not been pwned or compromised to other websites.'
										: 'You have been pwned ' +
										  displayStr +
										  'times. If you have used this password before, consider changing it immediately!'}
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!--Hero section end here--> */}

			{/* <!--Passworless is answer to password mess--> */}
			<section className='hero bg-light py-80'>
				{/* <div className='section-header text-center'> */}
				<div className='grid-60-40'>
					<div className='description'>
						<h2>Why is Breached safe to use?</h2>
						<p>
							You might be wondering if it is safe to share your password with
							us. Dont worry! Breached does not store any of your passwords.
						</p>
					</div>
					<div className='vector pl-5'>
						<img
							alt='Secure'
							src='assets/images/secure.svg'
							height='300px'
							width='300px'
						/>
					</div>
				</div>
				{/* <div className='grid-50'>
					<div className='card'>
						<div className='title'>Password</div>
						<ul>
							<li>Too many passwords to remember</li>
							<li>Weak passwords</li>
							<li>Password policies suck</li>
						</ul>
					</div>
					<div className='card'>
						<div className='title'>Passwordless</div>
						<ul>
							<li>No passwords to remember</li>
							<li>No need to make strong passwords</li>
							<li>No passwords, please</li>
						</ul>
					</div>
				</div> */}
			</section>
			{/* <!--Passworless is answer to password mess-->

			{/* <!--MojoAuth services start here--> */}
			<section className='services py-80'>
				<div className='section-header text-center'>
					<h2>Breached As A Service</h2>
				</div>
				{/* <Features /> */}
				<div className='grid-50 text-center'>
					<div className='card'>
						<div className='icon'>
							<img alt='star' src='assets/images/email-otp.svg' />
						</div>
						<div className='title'>Passwords</div>
						{/* <p>Email Magic Link</p> */}
						{/* <p>Email OTP</p> */}
					</div>
					<div className='card'>
						<div className='icon'>
							<img alt='star' src='assets/images/email-magic-link.svg' />
							{/* <img alt='star' src='assets/images/phone-otp.svg' /> */}
						</div>
						<div className='title'>Email Addresses</div>
						<p>coming soon</p>
					</div>
					{/* <div className='card'>
						<div className='icon'>
							<img alt='star' src='assets/images/webauthn.svg' />
						</div>
						<div className='title'>WebAuthn</div>
						<p>coming soon</p>
					</div> */}
				</div>
			</section>
			{/* <!--MojoAuth services start here--> */}

			{/* <!--Customize section start here--> */}
			{/* <section className="customize bg-light py-80">
        <div className="section-header text-center">
          <h2>Personalize your login interface</h2>
        </div>
        <div className="card p-0">
          <div className="editor p-32">
            <div className="title">Customization</div>
            <form>
              <div className="input-group">
                <label>Company Logo</label>
                <input type="text" placeholder="https://example.com/logo.png" />
              </div>
              <div className="input-group">
                <label>Font family</label>
                <select>
                  <option>'Inter', sans-serif</option>
                  <option>'Inter', sans-serif</option>
                  <option>'Inter', sans-serif</option>
                  <option>'Inter', sans-serif</option>
                </select>
              </div>
              <div className="input-group">
                <label>Button Color</label>
                <input type="text" placeholder="#5e00cf" />
              </div>
              <div className="input-group">
                <label>Background Color</label>
                <input type="text" placeholder="#E7E7E7" />
              </div>
              <div className="input-group">
                <label>Heading</label>
                <input type="text" placeholder="Welcome to Company" />
              </div>
              <div className="input-group">
                <label>Button Text</label>
                <input type="text" placeholder="Login" />
              </div>
            </form>
          </div>
          <div className="preview p-32 v-center">
            <div className="card shadow v-center">
              <div className="logo">
                <img src="assets/images/company_logo.svg" />
              </div>
              <div className="title">Welcome to Company</div>
              <div className="subtitle">
                Sign in or create a new account for free.
              </div>
              <form>
                <div className="form-group">
                  <input type="text" placeholder="email@example.com" />
                </div>
                <div className="form-group">
                  <input type="button" value="Login" />
                </div>
                <p>
                  by clicking login I agree with
                  <a href="#">terms of use</a> and
                  <a href="#">privacy policy</a>.
                </p>
              </form>
            </div>
          </div>
        </div>
      </section> */}
			{/* <!--Customize section end here--> */}

			{/* <!--Customize section start here--> */}

			{/* <section className='mojoauth-code py-80'>
				<div className='section-header text-center'>
					<h2>Code less, do more</h2>
				</div>
				<div>
					<div className='card'>
						<SyntaxHighlighter style={a11yDark} language='html'>
							{snippet}
						</SyntaxHighlighter>
						
					</div>
				</div>
			</section> */}

			{/* <!--Customize section end here--> */}

			{/* <!--Customize section start here--> */}
			{/* <section className='mojoAuth-box bg-light py-80'>
				<div className='section-header text-center'>
					<h2>In our MojoAuth box</h2>
				</div>
				<div className='grid-33 text-center'>
					<div className='card'>
						<div className='icon'>
							<img alt='secure' src='assets/images/secure.svg' />
						</div>
						<div className='title'>Secure</div>
						<p>Every layer is secure, from network to storage</p>
					</div>
					<div className='card'>
						<div className='icon'>
							<img alt='reliable' src='assets/images/reliable.svg' />
						</div>
						<div className='title'>Reliable</div>
						<p>
							Services are resilient and 100%
							<br />
							reliable
						</p>
					</div>
					<div className='card'>
						<div className='icon'>
							<img alt='experience' src='assets/images/experience.svg' />
						</div>
						<div className='title'>Experience</div>
						<p>Data driven experince reduce the friction of customers’ login</p>
					</div>
				</div>
			</section> */}
			{/* <!--Customize section end here--> */}

			{/* <!--Get started section start here--> */}
			<section className='get-started bg-light py-80'>
				<div className='section-header text-center'>
					<h2>Ready to get started?</h2>
					<p>
						There is no right or wrong time to ensure that your passwords are
						safe. Check if your password has not been compromised with Breached!
					</p>
					<div className='btn-group m-auto'>
						<a
							// href='https://6ohvv8yvg2b.typeform.com/to/Q2OUOiTh'
							className='btn btn-primary btn-cta'
						>
							<span>Check if your Password is secure</span>
							<svg
								width='20'
								height='20'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								className='feather feather-arrow-right'
							>
								<line x1='5' y1='12' x2='19' y2='12'></line>
								<polyline points='12 5 19 12 12 19'></polyline>
							</svg>
						</a>
						{/* <p className="help-text">No singup / password / credit card required</p> */}
					</div>
				</div>
			</section>
			{/* <!--Get started section end here--> */}

			{/* <!--Footer section start here--> */}
			<section className='footer'>
				<div className='inner h-center'>
					<div>
						Copyright 2021, Breached by{' '}
						<a href='https://www.compile7.com' target='_blank' rel='noreferrer'>
							Compile7 Inc.
						</a>
					</div>
					{/* <div>
						<a href='terms-of-use.html'>Terms of use</a> | &nbsp;
						<a href='privacy.html'>Privacy Policy</a>
					</div> */}
				</div>
			</section>
			{/* <!--Footer section ends here--> */}
		</React.Fragment>
	);
};

export default Landing;
