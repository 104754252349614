import * as React from 'react';
import './sidebar.scss';
import { NavLink, useHistory } from 'react-router-dom';

interface SideBarProps {
	hidePaths: string[];
}

const Sidebar: React.FC<SideBarProps> = ({ hidePaths }) => {
	const { location } = useHistory();
	const showSideNav = !(hidePaths && hidePaths.includes(location.pathname));

	if (showSideNav) {
		return (
			<div className='sidebar'>
				<ul>
					<li>
						<NavLink to='/overview' activeClassName='active'>
							<i className='icon-dashboard'></i>
							<span>Overview</span>
						</NavLink>
					</li>
					{/* <li>
						<NavLink to='/users' activeClassName='active'>
							<i className='icon-user--multiple'></i>
							<span>Users</span>
						</NavLink>
					</li> */}
					<li>
						<NavLink to='/branding' activeClassName='active'>
							<i className='icon-color-palette'></i>
							<span>Branding</span>
						</NavLink>
					</li>
					<li>
						<NavLink to='/settings' activeClassName='active'>
							<i className='icon-settings'></i>
							<span>Settings</span>
						</NavLink>
					</li>
					<li>
						<a
							href='https://mojoauth.com/docs'
							target='_blank'
							rel='noreferrer'
						>
							<i className='icon-document'></i>
							<span>Documentation</span>
							<i className='icon-new-tab'></i>
						</a>
					</li>
				</ul>
			</div>
		);
	}
	return null;
};

export default Sidebar;
