import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import { ProtectedRoute } from '../../components/ProtectedRoute';
import { isLoggedIn } from '../../utils/index';
import config from '../../config';
import axios from 'axios';
import { UserActions } from '../../redux/actions/user';
import { ProjectActions } from '../../redux/actions/project';
import { Redirect } from 'react-router-dom';
// Lazy loaded components
// const Landing = React.lazy(() => import('../Landing'));
// const Overview = React.lazy(() => import('../Overview'));
// // const Users = React.lazy(() => import('../Users'));
// const Branding = React.lazy(() => import('../Branding'));
// const Settings = React.lazy(() => import('../Settings'));
// const Auth = React.lazy(() => import('../Auth'));
// const MyProjects = React.lazy(() => import('../MyProjects'));
// const AddProject = React.lazy(() => import('../MyProjects/addProject'));

// Normal Loading
import Landing from '../Landing';
import Overview from '../Overview';
// import User from '../User';
import Branding from '../Branding';
import Settings from '../Settings';
import Auth from '../Auth';
import MyProjects from '../MyProjects';
import AddProject from '../MyProjects/addProject';

export const FALLBACK = (
	<div id='preloader' className='fullscreen'>
		<div id='status'>
			<div className='spinner'>
				<div className='double-bounce1'></div>
				<div className='double-bounce2'></div>
			</div>
		</div>
	</div>
);

const getProfileUrl = `${config.api_endpoint}/customer/profile`;
const getAllProjectsUrl = `${config.api_endpoint}/projects`;

// interface AppProps extends RouteComponentProps {
// 	setUser: (user: any) => void;
// 	setCurrentProject: (id: string) => void;
// 	setProjects: (projects: any[]) => void;
// 	user: any;
// 	projects: any;
// }

// Fix this any later on
class App extends Component<any> {
	SidebarHidePaths = [
		'/',
		'/pricing',
		'/signin',
		'/new',
		'/projects',
		'/signin/',
	];
	HeaderHidePaths = ['/', '/pricing', '/signin', '/new', '/signin/'];
	state = {
		fetchedUser: false,
		fetchedProjects: false,
	};
	componentDidMount() {
		// get customer profile from profile api using access token
		if (isLoggedIn()) {
			// with cookie won't have to pass like this
			const token = localStorage.getItem('AccessToken');
			axios
				.get(getProfileUrl, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then(({ data }) => {
					// 906 for invalid access token
					if (data.code === 906) {
						localStorage.removeItem('AccessToken');
						if (this.props.history.location.pathname !== '/') {
							this.props.history.push(
								`/signin?redirect=${
									this.props.history.location.pathname +
									this.props.history.location.search
								}`
							);
						} else {
							this.props.history.push('/');
						}

						return;
					}

					const { data: user } = data;
					this.props.setUser(user);
					this.setState({ fetchedUser: true });
					// after getting customer profile we need to get projects data
					axios
						.get(getAllProjectsUrl, {
							headers: {
								Authorization: `Bearer ${token}`,
							},
							params: {
								customerID: user.customer_id,
							},
						})
						.then(({ data }) => {
							let { data: projects } = data;

							if (!data['data']) {
								toast.error(data.message);
								return;
							}
							// let { data: projects } = data;
							// FIXME: data is null not [] in api
							if (projects === null) {
								projects = [];
							}

							this.props.setProjects(projects);
							this.setState({ fetchedProjects: true });
							if (projects.length) {
								this.props.setCurrentProject(projects[0]);
							}
						});
				});
		}
	}
	render() {
		const { user } = this.props;
		// const { fetchedProjects, fetchedUser } = this.state;
		// console.log('fetchedProjects ', fetchedProjects);
		// debugger;
		// case: if first login and projects length = 0
		// when access token exists signin not working
		if (isLoggedIn()) {
			// handling for loggedin users
			// step 1: check if we have got the user data
			if (!user.email) {
				return FALLBACK;
			} else {
				//we have got user now and need to check if first time user or not
				if (user.first_login) {
					return (
						<div className='main'>
							{!this.HeaderHidePaths.includes(
								this.props.history.location.pathname
							) && <Header />}
							<Sidebar hidePaths={this.SidebarHidePaths} />
							<Switch>
								<Route path='/' exact>
									<Landing />
								</Route>
								<Route path='/signin' exact>
									<Auth />
								</Route>
								{/* Protected Routes */}
								<ProtectedRoute path='/overview'>
									<Overview />
								</ProtectedRoute>
								{/* <ProtectedRoute path='/users'>
					
							<Users />
					</ProtectedRoute> */}
								<ProtectedRoute path='/branding'>
									<Branding />
								</ProtectedRoute>
								<ProtectedRoute path='/settings'>
									<Settings />
								</ProtectedRoute>
								<ProtectedRoute path='/projects'>
									<MyProjects />
								</ProtectedRoute>
								<ProtectedRoute path='/new'>
									<AddProject />
								</ProtectedRoute>
								<Redirect to='/' />
							</Switch>
							<ToastContainer />
						</div>
					);
				} else {
					if (
						!this.props.projects.length ||
						!Object.keys(this.props.currentProject).length
					) {
						return FALLBACK;
					} else {
						return (
							<div className='main'>
								{!this.HeaderHidePaths.includes(
									this.props.history.location.pathname
								) && <Header />}
								<Sidebar hidePaths={this.SidebarHidePaths} />
								<Switch>
									<Route path='/' exact>
										<Landing />
									</Route>
									<Route path='/signin' exact>
										<Auth />
									</Route>
									{/* Protected Routes */}
									<ProtectedRoute path='/overview'>
										<Overview />
									</ProtectedRoute>
									{/* <ProtectedRoute path='/users'>
					
							<Users />
					</ProtectedRoute> */}
									<ProtectedRoute path='/branding'>
										<Branding />
									</ProtectedRoute>
									<ProtectedRoute path='/settings'>
										<Settings />
									</ProtectedRoute>
									<ProtectedRoute path='/projects'>
										<MyProjects />
									</ProtectedRoute>
									<ProtectedRoute path='/new'>
										<AddProject />
									</ProtectedRoute>
									<Redirect to='/' />
								</Switch>
								<ToastContainer />
							</div>
						);
					}
				}
			}
		}
		// if (
		// 	isLoggedIn() &&
		// 	!this.props.user.first_login &&
		// 	(!this.props.user.email ||
		// 		!this.props.projects.length ||
		// 		!Object.keys(this.props.currentProject).length)
		// ) {
		// 	return FALLBACK;
		// }
		// if (isLoggedIn() && !Object.keys(this.props.user).length) {
		// 	return (
		// 		<div className='main'>
		// 			<Switch>
		// 				<Route path='/' exact>
		// 					<Landing />
		// 				</Route>
		// 				<Route path='/signin'>
		// 					<Auth />
		// 				</Route>
		// 				<Redirect to='/' />
		// 			</Switch>
		// 			<ToastContainer />
		// 		</div>
		// 	);
		// }
		return (
			<div className='main'>
				{!this.HeaderHidePaths.includes(
					this.props.history.location.pathname
				) && <Header />}
				<Sidebar hidePaths={this.SidebarHidePaths} />
				<Switch>
					<Route path='/' exact>
						<Landing />
					</Route>
					<Route path='/signin' exact>
						<Auth />
					</Route>
					{/* Protected Routes */}
					<ProtectedRoute path='/overview'>
						<Overview />
					</ProtectedRoute>
					{/* <ProtectedRoute path='/users'>
					
							<Users />
					</ProtectedRoute> */}
					<ProtectedRoute path='/branding'>
						<Branding />
					</ProtectedRoute>
					<ProtectedRoute path='/settings'>
						<Settings />
					</ProtectedRoute>
					<ProtectedRoute path='/projects'>
						<MyProjects />
					</ProtectedRoute>
					<ProtectedRoute path='/new'>
						<AddProject />
					</ProtectedRoute>
					<Redirect to='/' />
				</Switch>
				<ToastContainer />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
	projects: state.projects,
	currentProject: state.currentProject,
});

const mapDispatchToProps = (dispatch) => {
	return {
		setUser: (user: any) =>
			dispatch({
				type: UserActions.SET_PROFILE,
				payload: user,
			}),
		setCurrentProject: (id: string) =>
			dispatch({
				type: ProjectActions.SET_CURRENT_PROJECT,
				payload: id,
			}),
		setProjects: (projects: any[]) =>
			dispatch({
				type: ProjectActions.SET_PROJECTS,
				payload: projects,
			}),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
